const routes = {
  landing: [
    {
      name: "landing",
      path: "/",
      component: () => import("src/layouts/LandingLayout.vue"),
      children: [
        {
          name: "index",
          path: "",
          meta: {
            title: "Foundation Repair & Waterproofing Service",
            description:
              "Get expert foundation repair and waterproofing with bdryfoundations.com: Call (205) 942-1976 for professional service.",
          },
          component: () => import("src/pages/home/IndexPage.vue"),
        },
        {
          name: "weather",
          path: "weather",
          meta: {
            title: "Local weather for your lawn",
            description:
              "Get expert lawn care with bdryfoundations.com: mowing, blowing, weed control, edging, and more. Call (601) 808-5296 for top services.",
          },
          component: () => import("src/pages/home/weather/WeatherHome2.vue"),
        },
        {
          name: "weather-forecast",
          path: "weather-forecast",
          meta: {
            title: "Local weather forecast for your home",
            description:
              "Get expert lawn care with bdryfoundations.com: mowing, blowing, weed control, edging, and more. Call (601) 808-5296 for top services.",
          },
          component: () => import("src/pages/home/weather/WeatherIndex.vue"),
        },
        {
          name: "mobile-applications",
          path: "mobile-applications",
          meta: {
            title: "Mobile applications for your lawn",
            description: "Try our mobile applications for the best experience.",
          },
          component: () => import("src/pages/home/home/MobileApps.vue"),
        },
        /*{
          name: "state-weather",
          path: "weather/:state",
          meta: {
            title: "Local weather for your state",
          },
          component: () => import("src/pages/home/weather/StateWeather.vue"),
        },*/
        {
          name: "city-weather",
          path: "weather/:state/:city",
          meta: {},
          component: () => import("src/pages/home/weather/CityWeather.vue"),
        },
        {
          name: "providers map",
          path: "providers-map",
          component: () => import("src/pages/home/community/ProvidersMap.vue"),
        },

        /*{
          name: "pricing",
          path: "pricing",
          meta: {
            title: "Pricing & Plans for our Service",
            description:
              "Our plans & pricing make it simple for service providers to use our platform.  Customers never pay any service fees.",
          },
          component: () => import("src/pages/home/home/PricingPage2.vue"),
        },*/
        {
          name: "process",
          path: "how-it-works",
          meta: {
            title: "How our Service Process Works",
            description:
              "Our straightforward process makes it simple to obtain quotes and start services with qualified professionals via our application.",
          },
          component: () => import("src/pages/home/home/ProcessPage.vue"),
        },
        {
          name: "platform",
          path: "platform",
          meta: {
            title: "Mow & Blow Platform",
            description:
              "Our platform is designed to make your business succeed and thrive in today's environment. Control nearly your entire business from within our app.",
          },
          component: () => import("src/pages/home/PlatformPage.vue"),
        },
        {
          name: "marketplace",
          path: "marketplace",
          meta: {
            title: "Mow & Blow Marketplace",
            description:
              "Our top notch marketplace makes it so customers can get the best service experience possible. Review bids and decide after considering your options.",
          },
          component: () => import("src/pages/home/MarketplacePage.vue"),
        },
        {
          name: "about",
          path: "about",
          meta: {
            title:
              "Lawn Care and Landscaping Marketplace - About Mow &amp; Blow",
            description:
              "General information on the history and founding of bdryfoundations.com. Also discusses the key features and value propositions for our application.",
          },
          component: () => import("src/pages/home/home/AboutPage.vue"),
        },
        {
          name: "mission",
          path: "mission",
          meta: {
            title: "Mission of Mow &amp; Blow",
            description:
              "Our mission is simple - push button lawn care experience. We strive to create a super app for lawn service customers and professionals.",
          },
          component: () => import("src/pages/home/MissionPage.vue"),
        },
        {
          name: "communication",
          path: "communication",
          meta: {
            title: "Communication with Mow &amp; Blow",
            description:
              "The communication functionality in our application is second to none.",
          },
          component: () => import("src/pages/home/home/CommunicationPage.vue"),
        },
        {
          name: "billing",
          path: "billing",
          meta: {
            title:
              "Mow & Blow Billing: Flexible Payment Options - Pay-As-You-Go or Monthly",
            description:
              "Explore hassle-free billing with Mow & Blow: Choose Pay-As-You-Go for flexibility or Monthly Billing for consistent lawn care.",
          },
          component: () => import("src/pages/home/home/BillingPage.vue"),
        },
        {
          name: "scheduling",
          path: "scheduling",
          meta: {
            title:
              "Easy Lawn Care Scheduling | Book Your Service Online | Mow & Blow",
            description:
              "Book lawn care on your schedule with Mow & Blow. Quick setup, personalization, and automatic reminders for a hassle-free experience.",
          },
          component: () => import("src/pages/home/home/SchedulingPage.vue"),
        },
        {
          name: "service-providers",
          path: "service-providers",
          meta: {
            title: "Service Providers",
            description:
              "Service provider registration and search. View our existing service providers and access pages decidated to each of their companies.",
          },
          component: () =>
            import("src/pages/home/community/ServiceProviders.vue"),
        },
        {
          name: "service-provider",
          path: "service-providers/:id",
          component: () =>
            import("src/pages/home/community/ServiceProvider.vue"),
        },
        {
          name: "service-areas",
          path: "service-areas",
          meta: {
            title:
              "Lawn Care and Landscaping Marketplace - Mow &amp; Blow Service Areas",
            description: "Information on our areas of service.",
          },
          component: () =>
            import("src/pages/home/locations/StateLocations.vue"), //component: () => import("src/pages/home/ServiceArea.vue"),
        },
        {
          name: "calculators",
          path: "calculators",
          meta: {
            title:
              "Project Estimation Calculators for Lawn Service Customers and Pros",
            description: "List and description of the services we provide.",
          },
          component: () =>
            import("src/pages/home/calculators/CalculatorIndex.vue"),
        },
        {
          name: "services",
          path: "services",
          meta: {
            title:
              "Lawn Care and Landscaping Marketplace - Mow &amp; Blow Services",
            description: "List and description of the services we provide.",
          },
          component: () => import("src/pages/home/services/ServicesPage2.vue"),
        },
        {
          name: "service",
          path: "services/:id",
          component: () => import("src/pages/home/services/ServicePage.vue"),
        },
        {
          name: "help",
          path: "help",
          meta: {
            title:
              "Help Center - Answers to Frequently Asked Questions and more",
            description:
              "Answers to Frequently Asked Questions about lawn care, lawn mowing, shrub trimming, safety, fertilization, and more.",
          },
          component: () => import("src/pages/home/help/HelpIndex.vue"),
        },
        {
          name: "faqs",
          path: "faqs",
          meta: {
            title:
              "Help Center - Answers to Frequently Asked Questions and more",
            description:
              "Answers to Frequently Asked Questions about lawn care, lawn mowing, shrub trimming, safety, fertilization, and more.",
          },
          component: () => import("src/pages/home/help/HelpIndex.vue"),
        },
        {
          name: "residential",
          path: "residential-services",
          meta: {
            title: "Residential Services Available  - Mow & Blow",
            description:
              "List and description of the residential services we provide.",
          },
          component: () => import("src/pages/home/home/ResidentialPage2.vue"),
        },
        {
          name: "residential-service",
          path: "residential-services/:id",
          component: () => import("src/pages/home/services/ServicePage.vue"),
        },
        {
          name: "commercial",
          path: "commercial-services",
          meta: {
            title: "Commercial Services Available  - Mow & Blow",
            description:
              "List and description of the commercial services we provide.",
          },
          component: () => import("src/pages/home/home/CommercialPage2.vue"),
        },
        {
          name: "warranty",
          path: "warranty",
          meta: {
            title: "BDry Warranty - Industry-Leading Protection for Your Home",
            description:
              "Learn about BDry’s industry-leading warranties for foundation repair, waterproofing, and crawl space encapsulation. Enjoy peace of mind with long-term protection and reliability.",
          },
          component: () => import("src/pages/home/home/WarrantyPage.vue"),
        },
        {
          name: "financing",
          path: "financing",
          meta: {
            title:
              "BDry Financing Options - Affordable Foundation and Waterproofing Solutions",
            description:
              "Explore BDry’s flexible financing options for foundation repair, waterproofing, and crawl space services. Protect your home today with affordable payment plans.",
          },
          component: () => import("src/pages/home/home/FinancingPage.vue"),
        },
        {
          name: "faq",
          path: "faqs/:id",
          component: () => import("src/pages/home/help/FaqPage.vue"),
        },
        {
          name: "commercial-service",
          path: "commercial-services/:id",
          component: () =>
            import("src/pages/accounts/customer/portal/ServicePage.vue"),
        },
        {
          name: "local-service",
          path: "local/:location/:service",
          meta: {
            title:
              "Lawn Care and Landscaping Marketplace - Mow &amp; Blow Service",
          },
          component: () => import("src/pages/home/community/LocalService.vue"),
        },
        {
          name: "locations",
          path: "locations",
          meta: {
            title: "Service Area Overview  - States We Serve",
          },
          component: () => import("src/pages/home/locations/USLocations.vue"),
        },
        {
          name: "state",
          path: "locations/:state",
          meta: {
            title: "Service Area Overview  - States We Serve",
          },
          component: () =>
            import("src/pages/home/locations/StateLocations.vue"),
        },
        {
          name: "city",
          path: "locations/:state/:city",
          meta: {},
          component: () => import("src/pages/home/locations/CityLocations.vue"),
        },
        /* {
          name: "local-keyword",
          path: "community/:location/:keyword",
          meta: {
            title: "Lawn Care and Landscaping Marketplace - Mow &amp; Blow",
          },
          component: () =>
            import("src/pages/home/community/LocalKeyword.vue"),
        },*/
        {
          name: "careers",
          path: "careers",
          meta: {
            title:
              "Lawn Care and Landscaping Marketplace - Careers at Mow &amp; Blow",
            description: "Register for a new career with bdryfoundations.com",
          },
          component: () =>
            import("src/pages/home/contractor/ContractorPage.vue"),
        },
        {
          name: "contractors",
          path: "pros",
          meta: {
            title:
              "Lawn Care and Landscaping Marketplace - Mow &amp; Blow Professionals",
          },
          component: () => import("src/pages/home/careers/CareersPage.vue"),
        },
        {
          name: "careers",
          path: "careers",
          meta: {
            title: "Join Our Team - Careers at Mow & Blow",
          },
          component: () => import("src/pages/home/careers/CareersPage.vue"),
        },
        {
          name: "privacy",
          path: "privacy",
          meta: {
            title: "Privacy Policy - Mow & Blow ",
            description:
              "Privacy policy for bdryfoundations.com. This privacy policy must be consented to in order to use this website.",
          },
          component: () => import("src/pages/legal/PrivacyPolicy.vue"),
        },
        {
          name: "terms",
          path: "terms",
          meta: {
            title: "Terms of Use - Mow & Blow",
            description:
              "Terms of use for bdryfoundations.com. These terms of use must be consented to in order to use this website.",
          },
          component: () => import("src/pages/legal/TermsAndConditions.vue"),
        },
        {
          name: "copyright",
          path: "copyright",
          meta: {
            title:
              "Lawn Care and Landscaping Marketplace - Mow &amp; Blow Copyright",
            description: "Copyright information for bdryfoundations.com",
          },
          component: () => import("src/pages/legal/CopyrightPolicy.vue"),
        },
        {
          name: "policies",
          path: "policies",
          meta: {
            title: "Company Policies - Mow &amp; Blow",
            description:
              "List of company policies. Company policies for customers and service providers that must be consented to to use our application.",
          },
          component: () => import("src/pages/legal/CompanyPolicies.vue"),
        },
      ],
    },
    {
      name: "basic",
      path: "/",
      component: () => import("src/layouts/BasicLandingLayout.vue"),
      children: [
        {
          name: "reset-password",
          path: "/password-reset/confirm/:uid/:token",
          component: () =>
            import("src/pages/accounts/common/auth/PasswordResetPage.vue"),
        },
      ],
    },
    {
      name: "learn-index",
      path: "/learn",
      meta: {
        title:
          "Learn all about Your Lawn and How to Maintain It Overview of Common Types of Grass",
        description:
          "Read the articles and blog posts that Mow & Blow has created. These articles will teach you about lawn care techniques, products, and servicing expectations.",
      },
      component: () => import("src/pages/home/articles/LearnIndex.vue"),
    },
    {
      name: "grass",
      path: "/grass",
      component: () => import("src/layouts/ArticleLayout.vue"),
      children: [
        {
          name: "grass-index",
          path: "",
          meta: {
            title: "Overview of Common Types of Grass",
            description:
              "Read the articles and blog posts that Mow & Blow has created. These articles will teach you about lawn care techniques, products, and servicing expectations.",
          },
          component: () =>
            import("src/pages/home/articles/grass/GrassIndex.vue"),
        },
        {
          name: "centipede",
          path: "centipede-grass",
          meta: {
            title:
              "Centipede Grass - Info on Climate, Use, & Nutritional Needs",
            description:
              "Learn the basics of how to fertilize your lawn and plants. Article discusses the types of fertilizers and the key elements that make up fertilizer.",
          },
          component: () =>
            import("src/pages/home/articles/grass/CentipedeGrass.vue"),
        },
        {
          name: "bermuda",
          path: "bermuda-grass",
          meta: {
            title: "Bermuda Grass - Info on Climate, Use, & Nutritional Needs",
            description:
              "Learn the basics of how to trim and prune your plants. Article discusses the various methods and situations where you should trim your plants.",
          },
          component: () =>
            import("src/pages/home/articles/grass/BermudaGrass.vue"),
        },
        {
          name: "st-augustine",
          path: "st-augustine-grass",
          meta: {
            title:
              "St. Augustine Grass - Info on Climate, Use, & Nutritional Needs",
            description:
              "Learn the basics of how to trim and prune your plants. Article discusses the various methods and situations where you should trim your plants.",
          },
          component: () =>
            import("src/pages/home/articles/grass/StAugustineGrass.vue"),
        },
        {
          name: "zoysia",
          path: "zoysia-grass",
          meta: {
            title: "Zoysia Grass - Info on Climate, Use, and Nutritional Needs",
            description:
              "Learn the basics of how to trim and prune your plants. Article discusses the various methods and situations where you should trim your plants.",
          },
          component: () =>
            import("src/pages/home/articles/grass/ZoysiaGrass.vue"),
        },
        {
          name: "fescue",
          path: "fescue-grass",
          meta: {
            title: "Fescue Grass - Info on Climate, Use, & Nutritional Needs",
            description:
              "Learn the basics of how to trim and prune your plants. Article discusses the various methods and situations where you should trim your plants.",
          },
          component: () =>
            import("src/pages/home/articles/grass/FescueGrass.vue"),
        },
        {
          name: "kentucky-bluegrass",
          path: "kentucky-bluegrass",
          meta: {
            title:
              "Kentucky Bluegrass - Info on Climate, Use, & Nutritional Needs",
            description:
              "Learn the basics of how to trim and prune your plants. Article discusses the various methods and situations where you should trim your plants.",
          },
          component: () =>
            import("src/pages/home/articles/grass/KentuckyBluegrass.vue"),
        },
        {
          name: "ryegrass",
          path: "ryegrass",
          meta: {
            title: "Ryegrass - Info on Climate, Use, & Nutritional Needs",
            description:
              "Learn the basics of how to trim and prune your plants. Article discusses the various methods and situations where you should trim your plants.",
          },
          component: () => import("src/pages/home/articles/grass/RyeGrass.vue"),
        },
      ],
    },
    {
      name: "mowers",
      path: "/mowers",
      component: () => import("src/layouts/ArticleLayout.vue"),
      children: [
        {
          name: "mowers-index",
          path: "",
          meta: {
            title: "Kinds of Lawn Mowers",
            description:
              "Read the articles and blog posts that Mow & Blow has created. These articles will teach you about lawn care techniques, products, and servicing expectations.",
          },
          component: () =>
            import("src/pages/home/articles/mowers/MowersIndex.vue"),
        },
        {
          name: "push-mower",
          path: "push-mower",
          meta: {
            title: "Push Mowers",
            description:
              "Learn the basics of how to fertilize your lawn and plants. Article discusses the types of fertilizers and the key elements that make up fertilizer.",
          },
          component: () =>
            import("src/pages/home/articles/mowers/PushMower.vue"),
        },
        {
          name: "self-propelled-mower",
          path: "self-propelled-mower",
          meta: {
            title: "Self-Propelled Mowers",
            description:
              "Learn the basics of how to fertilize your lawn and plants. Article discusses the types of fertilizers and the key elements that make up fertilizer.",
          },
          component: () =>
            import("src/pages/home/articles/mowers/SelfPropelledMower.vue"),
        },
        {
          name: "riding-mower",
          path: "riding-mower",
          meta: {
            title: "Riding Mowers",
            description:
              "Learn the basics of how to fertilize your lawn and plants. Article discusses the types of fertilizers and the key elements that make up fertilizer.",
          },
          component: () =>
            import("src/pages/home/articles/mowers/RidingMower.vue"),
        },
        {
          name: "zero-turn-mower",
          path: "zero-turn-mower",
          meta: {
            title: "Zero-Turn Mowers",
            description:
              "Learn the basics of how to fertilize your lawn and plants. Article discusses the types of fertilizers and the key elements that make up fertilizer.",
          },
          component: () =>
            import("src/pages/home/articles/mowers/ZeroTurnMower.vue"),
        },
        {
          name: "stand-on-mower",
          path: "stand-on-mower",
          meta: {
            title: "Stand-on Mowers",
            description:
              "Learn the basics of how to fertilize your lawn and plants. Article discusses the types of fertilizers and the key elements that make up fertilizer.",
          },
          component: () =>
            import("src/pages/home/articles/mowers/StandOnMower.vue"),
        },
      ],
    },
    {
      name: "articles",
      path: "/articles",
      component: () => import("src/layouts/ArticleLayout.vue"),
      children: [
        {
          name: "article-index",
          path: "",
          meta: {
            title: "Articles - Mow & Blow",
            description:
              "Read the articles and blog posts that Mow & Blow has created. These articles will teach you about lawn care techniques, products, and servicing expectations.",
          },
          component: () => import("src/pages/home/articles/ArticleIndex.vue"),
        },
        {
          name: "fertilization-101",
          path: "fertilization-101",
          meta: {
            title: "Fertilization 101: How to Fertilize Your Lawn and Plants",
            description:
              "Learn the basics of how to fertilize your lawn and plants. Article discusses the types of fertilizers and the key elements that make up fertilizer.",
          },
          component: () =>
            import("src/pages/home/articles/Fertilization101Page.vue"),
        },
        {
          name: "shrub-trimming-101",
          path: "shrub-trimming-101",
          meta: {
            title: "Shrub Trimming 101: How to Trim & Prune Shrubs",
            description:
              "Learn the basics of how to trim and prune your plants. Article discusses the various methods and situations where you should trim your plants.",
          },
          component: () =>
            import("src/pages/home/articles/ShrubTrimming101.vue"),
        },
        {
          name: "lawn-mowing-101",
          path: "lawn-mowing-101",
          meta: {
            title:
              "Lawn Mowing 101: A Beginner's Guide to Perfecting Your Lawn",
            description:
              "Learn the basics of how to mow and maintain your lawn. Article discusses how to understand the needs of your lawn and maintain it.",
          },
          component: () => import("src/pages/home/articles/LawnMowing101.vue"),
        },
      ],
    },

    {
      name: "catchAll",
      path: "/:catchAll(.*)*",
      component: () => import("pages/accounts/common/auth/ErrorNotFound.vue"),
    },
  ],
  customer: [
    {
      name: "customer",
      path: "/",
      component: () => import("src/layouts/CustomerLayout.vue"),
      children: [
        {
          name: "index",
          path: "",
          component: () =>
            import("src/pages/accounts/customer/common/CustomerIndex.vue"),
        },
        {
          name: "disclosures",
          path: "disclosures",
          component: () =>
            import("src/pages/accounts/customer/portal/DisclosuresPage.vue"),
        },
        {
          name: "change-password",
          path: "/change-password",
          component: () =>
            import("src/pages/accounts/common/auth/ChangePassword.vue"),
        },

        {
          name: "application",
          path: "application",
          component: () =>
            import(
              "src/pages/accounts/customer/registration/CustomerRegistration.vue"
            ),
        },
        {
          name: "new-user",
          path: "new-user",
          component: () =>
            import("src/pages/accounts/customer/registration/NewUserSetup.vue"),
        },

        {
          name: "user",
          path: "user",
          component: () => import("src/components/portal/User.vue"),
        },
        {
          name: "security",
          path: "security",
          component: () => import("src/components/portal/Security.vue"),
        },
        {
          name: "terms",
          path: "terms",
          component: () => import("src/pages/legal/TermsAndConditions.vue"),
        },
        {
          name: "privacy",
          path: "privacy",
          component: () => import("src/pages/legal/PrivacyPolicy.vue"),
        },
        {
          name: "profile",
          path: "/profile",
          component: () =>
            import("src/pages/accounts/professional/crew/CrewProfile.vue"),
        },
        {
          name: "preferences",
          path: "/preferences",
          component: () =>
            import("src/pages/accounts/customer/portal/UserPreferences.vue"),
        },
      ],
    },
    {
      name: "catchAll",
      path: "/:catchAll(.*)*",
      component: () => import("pages/accounts/common/auth/ErrorNotFound.vue"),
    },
  ],
};

export default routes;
