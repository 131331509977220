import { boot } from "quasar/wrappers";

import axios from "axios";

import { LocalStorage } from "quasar";

import { useAuthStore } from "src/stores/auth.js";

// import Router from "src/router/index.js";

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)

const api = axios.create({
  baseURL: process.env.DEV ? "/api" : "https://api.4way.io/v1/api",
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
  headers: {
    Tenant: "mowandblow",
  },
});

const base = axios.create({
  baseURL: "/",
});

const blog = axios.create({
  baseURL: "https://www.bdryfoundations.com/blog",
});

const weather = axios.create({
  baseURL: "https://api.weather.gov/",
});

const hatch = axios.create({
  baseURL: "https://app.usehatchapp.com/api/webhooks/6413934f07965e238524f04b",
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
  headers: {
    "X-API-KEY": "c389aef4-7cab-413a-becf-90dab12ad957",
    "Content-Type": "application/json",
  },
});

export default boot(() => {
  api.interceptors.request.use((config) => {
    const authToken = LocalStorage.getItem("authToken");
    if (authToken) {
      config.headers["Authorization"] = `Token ${authToken}`;
    }

    return config;
  });
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status == 401) {
        const auth = useAuthStore();
        auth.isLoggedIn = false;
        auth.token = null;
        auth.user = null;
        LocalStorage.remove("isLoggedIn");
        LocalStorage.remove("authToken");
        LocalStorage.remove("authUser");
        // Router.replace("/");
      }
      Promise.reject(error);
    }
  );
});

export { api, base, blog, weather, hatch };
